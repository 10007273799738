import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CaptureInputComponent } from './capture-input/capture-input.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { DisplayCalculationComponent } from './display-calculation/display-calculation.component';
import { MainComponent } from './main/main.component';
import { TermsComponent } from './terms/terms.component';
import { FlowGuard } from './_guards/flow.guard';

const routes: Routes = [
  { path: '', component: MainComponent},
  {
    path:'',
    runGuardsAndResolvers: 'always',
    canActivate: [FlowGuard],
    children: [
      { path: 'input', component: CaptureInputComponent},
      { path: 'calculation', component: DisplayCalculationComponent},
      { path: 'details', component: CustomerDetailsComponent},
      { path: 'terms', component: TermsComponent},
    ]
  },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
