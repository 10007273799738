import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, AfterViewInit, ViewChild } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import * as Highcharts from 'highcharts';
import solidGauge from 'highcharts/modules/solid-gauge';
import more from 'highcharts/highcharts-more';
import {ColorType, PaneBackgroundOptions, YAxisOptions, YAxisPlotLinesOptions, PlotAbandsOptions, PointOptionsObject} from 'highcharts/highstock';

import { ICarbonConfig, IFormulaConfig } from '../interfaces/CarbonConfig';
import { ICarbonInput } from '../interfaces/CarbonInput';
import { AppConfigService } from '../services/app-config.service';
import { CarbonFootprintService } from '../services/carbon-footprint.service';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { retry } from 'rxjs/internal/operators/retry';
import { catchError } from 'rxjs/internal/operators/catchError';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LoaderComponent } from '../loader/loader.component';


more(Highcharts);
solidGauge(Highcharts);

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export interface ICarbonDataObject{
  Co2Output: number;
  Co2Captured: number;
  carbonFootprint: number;
  plantRequired: number;
}


@Component({
  selector: 'app-display-calculation',
  templateUrl: './display-calculation.component.html',
  styleUrls: ['./display-calculation.component.scss']
})

export class DisplayCalculationComponent implements OnInit, AfterViewInit {
  @ViewChild('gaugeContainer') gaugeContainer;
  @ViewChild('gaugePositiveLabel') gaugePositiveLabel;
  @ViewChild('gaugeNegativeLabel') gaugeNegativeLabel;
  
  
  serverUrl="https://databuscloud.indicium.cloud/api/rest/apikey/in-restapi-tfncarbonpost/in-restapi-tfncarbonpost-savemessage"
  
  prograssVal = 100;
  capturedInputs: ICarbonInput = this.carbonFootprintService.getCarbonInput();
  config: IFormulaConfig = this.appConfigService.getFormulaConfigObj();  
  durationInSeconds: number = 5;

  carbonOutputColour='rgb(255, 0, 0)';
  carbonCapturedColour='rgb(146, 208, 80)';
  totalCarbonFootprintColour='rgb(0, 32, 96)';
  originalPlantation:number=0;
  originalFootprint:number=0;
  sliderMin:number = 0 ;
  sliderMax:number = 0 ;
  dataObj:ICarbonDataObject={
    Co2Output: 0,
    Co2Captured: 0,
    carbonFootprint: 0,
    plantRequired:0
  };
  
  displayVal = this.getGaugeDisplayValue(this.capturedInputs);
  gaugeMin = this.getGaugeMin(this.displayVal);
  gaugeMax = this.getGaugeMax(this.displayVal);


  
  Highcharts: typeof Highcharts = Highcharts;
  
  constructorType = 'chart';
  


  gaugeChart: Highcharts.Chart;
  guagechartData: Highcharts.SeriesXrangeOptions;
  gaugeChartCallback: Highcharts.ChartCallbackFunction;
  gaugeChartOptions:Highcharts.Options = this.getGaugeChartOption();
  gaugeChartUpdateFlag = false;

  isEmailMeNeeded = false;
  isSubmitDisabled = true;

  constructor(private http: HttpClient, private router: Router, private appConfigService: AppConfigService, 
    private carbonFootprintService: CarbonFootprintService, private cdref: ChangeDetectorRef,
    private storageService: StorageService, private _snackBar: MatSnackBar, public dialog: MatDialog) { }
  
  //Getting rid of NG0100: ExpressionChangedAfterItHasBeenCheckedError
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {

    this.sumUpInputData();
    //this method depends on sumUpInputData() to sum the totalArea of land
    const isAccessEligible = this.capturedInputs.total_landArea > 0 ? true: false;
    if(!isAccessEligible) this.router.navigate(['/input']);

    this.updateSliderConfig();
    //Update Gauge min / max upon update
    this.UpdateGaugeChartConfig();
    this.updateSliderConfig();
    this.gaugeChartOptions = this.getGaugeChartOption();
    //update Input page number
    this.storageService.storeInputPageNum(4);
    
    this.guagechartData = this.configureChartData();
    // this.updateData(true, false);
    //this.updateData(false);    
    this.gaugeChartCallback = chart => {
      this.gaugeChart = chart;
      this.updateData(true);
    };

    this.originalPlantation =this.capturedInputs.plantationForest; 
    this.originalFootprint = this.dataObj.carbonFootprint

  }

  
  private sumUpInputData(){
    const {cropping, livestock, remnantVegetaton} = this.capturedInputs;
    this.capturedInputs.total_landArea = cropping + livestock + remnantVegetaton;
  }

  private updateSliderConfig(){
    this.sliderMax = this.capturedInputs.total_landArea;
  }

  ngAfterViewInit(){
    this.updateGaugeOverlayLabels();
  }
  updateGaugeOverlayLabels(){
    /*const yPos = '60%';
    const posLabelEle = this.gaugePositiveLabel.nativeElement;
    const negLabelEle = this.gaugeNegativeLabel.nativeElement;
    const containerWidth = this.gaugeContainer.nativeElement.getBoundingClientRect().width;
    console.log(containerWidth);
    const positiveLabelWidth = posLabelEle.getBoundingClientRect().width;
    const negativeLabelWidth = negLabelEle.getBoundingClientRect().width;
    
    const positivetWidthInPercent = ((positiveLabelWidth / containerWidth) * 100);
    const negativetWidthInPercent = ((negativeLabelWidth / containerWidth) * 100);
    
    // var rectWidthInPercent = ((labelWidthTotal / containerWidth) * 100);
    let positiveLabelPos = 86 - positivetWidthInPercent;
    let negativeLabelPos = 56 - negativetWidthInPercent;
    //debugger;
    if(window.innerWidth > 1268 && window.innerWidth < 1350){
      positiveLabelPos +=2;
      negativeLabelPos -=2;
    }else if(window.innerWidth > 1068 && window.innerWidth < 1350){
      positiveLabelPos +=5;
      negativeLabelPos -=4;
    }else if(window.innerWidth > 868 && window.innerWidth < 1350){
      positiveLabelPos +=12;
      negativeLabelPos -=10;
    }else if(window.innerWidth > 700 && window.innerWidth < 1350){
      positiveLabelPos +=17;
      negativeLabelPos -=10;
    }

    posLabelEle.style.left = `${positiveLabelPos}%`;
    posLabelEle.style.top = yPos;
    
    negLabelEle.style.left = `${negativeLabelPos}%`;
    negLabelEle.style.top = yPos;*/
  }


  getGaugeChartOption():Highcharts.Options{
    const paneOption: PaneBackgroundOptions = {
      backgroundColor: 'transparent',
      innerRadius: '60%',
      outerRadius: '100%',
      shape: 'arc'
    };

    let plotLineOptions: Array<YAxisPlotLinesOptions> = [];
    const plotLine:YAxisPlotLinesOptions = {
      // color: "grey",
      // color: 'black',
      // dashStyle: "LongDash",
      // width: 1,
      // value: 100,
      // zIndex: 10
    }
    plotLineOptions.push(plotLine);
    
    
    return {
      title: {
        text: 'Carbon Neutral',
        y: 90
      },
      chart: {
        type: 'gauge',
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        width: null,
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      pane: {
        center: ['50%', '70%'],
        size: '100%',
        startAngle: -90,
        endAngle: 90,
        background: [paneOption],
      },
      exporting: {
        enabled: false
      },

      tooltip: {
        enabled: true
      },
      yAxis: {
        min: this.gaugeMin,
        max: this.gaugeMax,
        
        title: {
          text: '',
        },
        minorTickInterval: 'null',

    
        tickPixelInterval: 50,
        tickWidth: 2,
        tickPosition: 'inside',
        tickColor: '#666',
        labels: {
          step: 2,
          y: 16
        },
        plotBands: [{
          from: this.gaugeMin,
          to: (this.gaugeMin * 0.5),
          color: '#d50000' 
        }, {
          from: (this.gaugeMin * 0.5),
          to: 0,
          color: '#ffd600' 
        }, {
          from: 0,
          to: this.gaugeMax,
          color: '#00c853' 
        }]
      },
      plotOptions: {
        //gauge value config
        solidgauge: {
          dataLabels: {
            borderWidth: 0,
            useHTML: true
          },
        }
      },
      credits: {
        enabled: false
      }
    };
  }

  getGaugeDisplayValue(capturedInputs:ICarbonInput):number{
    const gaugeConfigValue = capturedInputs.total_landArea * this.config.co2Captured.plantationForestPerHa;
    const displayVal = Math.round(gaugeConfigValue);
    return displayVal;
  }

  getGaugeMin(val:number):number{
    return val * -1.3;
  }

  getGaugeMax(val:number):number{
    return val * 1.3;
  }

  getDataObj():ICarbonDataObject{
    const dataObj = {} as ICarbonDataObject;
    dataObj.Co2Output = this.calculateCO2Output();
    dataObj.Co2Captured = this.calculateCO2Captured();    
    dataObj.carbonFootprint = parseFloat((dataObj.Co2Captured + dataObj.Co2Output).toFixed(2));
    if(this.originalFootprint==0){
      this.originalFootprint = dataObj.carbonFootprint;
    }
    dataObj.plantRequired = parseFloat((((this.originalFootprint*-1) / this.config.co2Captured.plantationForestPerHa)).toFixed(2));
    if(dataObj.plantRequired<0){
      dataObj.plantRequired = 0; //Do not have a negative requirement
    }
    return dataObj;
  }

  updateData(isGaugeChartNeedUpdated: boolean){
    this.dataObj = this.getDataObj();

    // this.dataObj.Co2Output = this.calculateCO2Output();
    // this.dataObj.Co2Captured = this.calculateCO2Captured();
    // this.dataObj.carbonFootprint = parseFloat((this.dataObj.Co2Captured + this.dataObj.Co2Output).toFixed(2));
    //this.dataObj.plantRequired = parseFloat(((this.dataObj.Co2Output*-1)/ this.config.co2Captured.plantationForestPerHa).toFixed(2));
    if(isGaugeChartNeedUpdated){
      this.guagechartData.data.push({y: 0} as any);
      if(this.gaugeChart.series.length > 0){
        // update
        this.gaugeChart.series[0].points[0].update(this.dataObj.carbonFootprint);
      }else{
        // init
        this.gaugeChart.addSeries(this.guagechartData);
        // update
        this.gaugeChart.series[0].points[0].update(this.dataObj.carbonFootprint);
      }
      
      this.gaugeChartUpdateFlag = true;
    }
  }

  UpdateGaugeChartConfig(){
    const gaugeConfigValue = this.capturedInputs.total_landArea * this.config.co2Captured.plantationForestPerHa;
    const displayVal = Math.round(gaugeConfigValue);

    this.gaugeMin = this.getGaugeMin(displayVal);
    this.gaugeMax = this.getGaugeMax(displayVal);
    this.gaugeChartUpdateFlag = true;
  }

  configureChartData(): Highcharts.SeriesXrangeOptions {
      return {
        name: "Carbon Footprint",
        data: [],
        type: undefined, 
        dataLabels: {
          useHTML: true,
          enabled: true,
          format:
          '<div style="text-align:center">' +
          '<span style="font-size:18px">{y}</span><br/>' +
          '<span style="font-size:12px;opacity:0.4">tonnes per annum</span>' +
          '</div>'
        },
        tooltip: {
          valueSuffix: " tonnes per annum"
        }
      };
    
  }


  calculateCO2Output():number{
    const {cattlePerHead, sheepPerHead, dairyPerHead, grainsAndSeedsPerHa, horticulturePerHa, dseOutput} = this.config.co2Output;
    const {livestock, cattle, sheep, dairy, horticulture, grainsAndSeeds, dse, useDse} = this.capturedInputs;

    // if not DSE
    if (!useDse) {
      const cattleCo2 = cattle * cattlePerHead;
      const sheepCo2 = sheep * sheepPerHead;
      const dairyCo2 = dairy * dairyPerHead;

      const horticultureCo2 = horticulture * horticulturePerHa;
      const grainsAndSeedsCo2 = grainsAndSeeds * grainsAndSeedsPerHa;

      //making the total negative
      const totalCo2Output = (cattleCo2 + sheepCo2 + dairyCo2 + horticultureCo2 + grainsAndSeedsCo2) * -1;
      return parseFloat(totalCo2Output.toFixed(2));
    }
    // if DSE
    else {
      const dseNumber = dse * dseOutput * livestock;
      
      const horticultureCo2 = horticulture * horticulturePerHa;
      const grainsAndSeedsCo2 = grainsAndSeeds * grainsAndSeedsPerHa;

      //making the total negative
      const totalCo2Output = (dseNumber + horticultureCo2 + grainsAndSeedsCo2) * -1;
      return parseFloat(totalCo2Output.toFixed(2));
    }
  }
  
  calculateCO2Captured():number{
    const {remnantVegetationPerHa, plantationForestPerHa, grazingPasturePerHa, mixedNativeForestPerHa} = this.config.co2Captured;
    const {remnantVegetaton, plantationForest, grazingPasture, mixedNativeForest} = this.capturedInputs;

    const remnantVegetationCap = remnantVegetaton * remnantVegetationPerHa;
    const plantationForestCap = plantationForest * plantationForestPerHa;
    const grazingPastureCap = grazingPasture * grazingPasturePerHa;
    const mixedNativeForestCap = mixedNativeForest * mixedNativeForestPerHa;
    const totalCo2Captured = remnantVegetationCap + plantationForestCap + grazingPastureCap + mixedNativeForestCap;
    return parseFloat(totalCo2Captured.toFixed(2));
  }

  calculateCO2CapturedWithout():number{
    const {remnantVegetationPerHa, plantationForestPerHa, grazingPasturePerHa, mixedNativeForestPerHa} = this.config.co2Captured;
    const {remnantVegetaton, plantationForest, grazingPasture, mixedNativeForest} = this.capturedInputs;

    const remnantVegetationCap = remnantVegetaton * remnantVegetationPerHa;
    //const plantationForestCap = plantationForest * plantationForestPerHa;
    const grazingPastureCap = grazingPasture * grazingPasturePerHa;
    const mixedNativeForestCap = mixedNativeForest * mixedNativeForestPerHa;
    const totalCo2Captured = remnantVegetationCap + grazingPastureCap + mixedNativeForestCap;
    return parseFloat(totalCo2Captured.toFixed(2));
  }

  onSliderChange(event: MatSliderChange){
    this.capturedInputs.plantationForest = (event.value) ? event.value:0 ;    
    this.updateData(true);
  }

  onRecaptchaResponse(token: string){
    if(token?.length > 0){
      this.isSubmitDisabled = false;
      this.capturedInputs.captchaToken = token;
    }else{
      this.isSubmitDisabled = true;
    }
  }

  private finaliseSendData(){
    //update total offset
    this.capturedInputs.total_offset_calculated = this.dataObj.carbonFootprint;
    this.capturedInputs.co2Captured = this.dataObj.Co2Captured;
    this.capturedInputs.co2Output = this.dataObj.Co2Output *-1;
    this.capturedInputs.carbonFootprint = this.dataObj.carbonFootprint;
    this.capturedInputs.calcVersion= this.appConfigService.getConfigVersion().version;
    this.capturedInputs.datetime = Date.now().toString();

    this.capturedInputs.additionalPlantationForest = this.capturedInputs.plantationForest - this.originalPlantation;
  }

  public submit(){
    this.finaliseSendData();
    
    console.log(this.capturedInputs);
    this.sendData().subscribe((response)=>{
      this.dialog.closeAll();

      //success condition
      console.log(response);
      this.openSuccessSnackBar("Your data has been submitted to us. Thank you!");
    });
  }

  private openLoader(){
    this.dialog.open(LoaderComponent,{
      disableClose:true
    });
  }

  private sendData(){
    this.openLoader();
    const key = this.appConfigService.getConfigFunctionKey();
    
    const bodyParams = this.capturedInputs;
    const header = new HttpHeaders({ "API-KEY": key, "Content-Type":"application/json" });
    const requestOptions = {  headers: header};

    return this.http.post(this.serverUrl,bodyParams, requestOptions)
    .pipe(
      // retry(3),
      catchError(this.handleError<any>('Send server', [])));
  }

  public back(){
    this.router.navigate(['/input',{"from":"app"}]);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.dialog.closeAll();
      const errorMessage = `Status:${error.status} - ${error.statusText} \n
      Error: ${error.name} - ${error.message}`;
      // console.error(errorMessage);
      
      this.openErrorSnackBar(errorMessage);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  onCustomerDetailsLoaded(){
    const custDetailsEle = document.getElementById('customerDetails');
    custDetailsEle.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  onEmailChecked(checked: boolean) {
    this.capturedInputs.email_me = checked;
  }

  onContactMeCheckedCallback(checked: boolean) {
    this.capturedInputs.contact_me = checked;
  }

  private openErrorSnackBar(errorMessage: string) {
    this._snackBar.open(`Error: ${errorMessage}`, '', {
      duration: this.durationInSeconds * 1000,
      panelClass: ['red-snackbar'],
    });
  }

  private openSuccessSnackBar(message: string) {
    this._snackBar.open(`Sucess: ${message}`, '', {
      duration: this.durationInSeconds * 1000,
      panelClass: ['green-snackbar'],
    });
  }

}
