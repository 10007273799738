<div class="container">
    <footer class="d-flex justify-content-center">
      <div class="d-flex align-items-center">
        <a href="https://www.indicium.cloud" target="_blank" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
          <font size="2" class="text-muted app-disclaimer">&copy; 2022 Indicium Dynamics Pty. Ltd. &nbsp;</font>
        </a>
      </div>
  
      <ul class="nav justify-content-end list-unstyled d-flex">
        <li class="ms-3"><a class="text-muted app-disclaimer" target="_blank" href="/terms">
          <font size="2" class="app-disclaimer">Terms & Conditions</font></a>
        </li>
        <li class="ms-3">
          <font size="2" class="app-disclaimer">{{version}}</font>
        </li>
      </ul>
    </footer>
  </div>