import { Component, OnInit } from '@angular/core';
import { IVersionConfig } from '../interfaces/CarbonConfig';

import { AppConfigService } from '../services/app-config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version: IVersionConfig;
  constructor(private appConfigService: AppConfigService) { }
  
  ngOnInit(): void {
    this.version = this.appConfigService.getConfigVersion();
  }

}
