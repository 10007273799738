
<div class="d-flex h-100 text-center">
    <div class="container">

      <div class="card text-center card-top">
        <div class="card-header">
          <div class="app-heading-2">
            FARM CARBON FOOTPRINT ESTIMATOR
          </div>
        </div>
        <div class="card-body">

          <section class="text-center">
              <p class="lead card-text app-text card-body-text">
                Climate change is one of the greatest challenges of our time. Businesses and organisations are committing to operating as carbon neutral as early as the year 2030.
              </p>
              <p class="lead card-text app-text card-body-text">
                The Carbon Estimator is simply that – an estimator. It is designed to give a snapshot of a farm’s carbon footprint and what impact trees may have on improving the environmental outcomes. 
              </p>  
          </section>

          <p class="lead d-flex justify-content-center text-center button-container">
            <button (click)="startCalculation()"
            class="btn btn-lg btn-secondary mg-btm-1 button-start-calculation fw-bold app-button-text border-white">
              {{ 'Start Calculation' | uppercase }}
            </button>
          </p>

          <section class="text-center">
            <p class="lead card-text app-text card-body-text">
              <a href="https://www.tffpn.com.au/" target="_blank">
                <img src="assets/tas-hub-logo.jpg" width="200px">
              </a>
            </p>
        </section>
          

        </div>
        <div class="card-footer text-muted">
          &nbsp;
        </div>
      </div>
  </div>
</div>