
        <mat-accordion class="headers-align" multi>
            <mat-expansion-panel expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title class="h5">
                  Personal data
                </mat-panel-title>
                <mat-panel-description>
                  <mat-icon>account_circle</mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <form autocomplete="off">
                  <section class="row">
                      <div class="col-sm-6">
                          <mat-form-field appearance="outline">
                              <mat-label>First Name</mat-label>
                              <input name="name" matInput [(ngModel)]="capturedInputs.name" type="text">
                          </mat-form-field>
                      </div>
          
                      <div class="col-sm-6">
                          <mat-form-field appearance="outline">
                              <mat-label>Last Name</mat-label>
                              <input name="surname" [(ngModel)]="capturedInputs.surname" matInput type="text">
                          </mat-form-field>
                      </div>
                  </section>
                  <section class="row">
                    <div class="col-sm-6">
                      <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput name="email" [(ngModel)]="capturedInputs.email" placeholder="@example.com" type="email" required>
                        <!-- <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error> -->
                    </mat-form-field>
                  </div>
                  <div class="col-sm-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Phone</mat-label>
                      <input matInput name="phone" [(ngModel)]="capturedInputs.phone" type="tel" required>
                      <!-- <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error> -->
                  </mat-form-field>
                </div>
                </section>
                  <section class="row">
                      <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Address</mat-label>
                            <input name="address" [(ngModel)]="capturedInputs.address" matInput type="text">
                        </mat-form-field>
                      </div>
                  </section>
    
                  <section class="row">
                      <div class="col-md-5">
                        <mat-form-field appearance="outline">
                          <mat-label>Suburb</mat-label>
                          <input name="suburb" [(ngModel)]="capturedInputs.suburb" matInput type="text">
                        </mat-form-field>
                      </div>
          
                      <div class="col-md-4">
                        <mat-form-field appearance="fill">
                          <mat-label>Select</mat-label>
                          <mat-select [disabled]="true" [(ngModel)]="selectedState" name="state">
                            <mat-option *ngFor="let state of states" [value]="state.value">
                              {{state.label}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
          
                      <div class="col-md-3">
                        <mat-form-field appearance="outline">
                          <mat-label>Postcode</mat-label>
                          <input name="postcode" [(ngModel)]="capturedInputs.postcode" matInput type="text">
                        </mat-form-field>
                      </div>
                  </section>
                  <section  class="row">
                    <div>
                      <mat-checkbox class="app-text" (change)="onContactMeChecked($event.checked)">Please contact me with more information</mat-checkbox>
                    </div>
                  </section>
              </form>
            </mat-expansion-panel>
</mat-accordion>