import { AfterViewInit,Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { ICarbonInput } from '../interfaces/CarbonInput';
import { CarbonFootprintService } from '../services/carbon-footprint.service';

interface State {
  value: string;
  label: string;
}

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})

export class CustomerDetailsComponent implements OnInit, AfterViewInit {
  @Output() customerDetailsLoaded = new EventEmitter<void>();
  @Output() contactMeChecked = new EventEmitter<boolean>();

  capturedInputs: ICarbonInput = this.carbonFootprintService.getCarbonInput();
  
  states: State[] = [
    {value: 'QLD', label: 'Queensland'},
    {value: 'NSW', label: 'New South Wales'},
    {value: 'ACT', label: 'Australian Capital Territory'},
    {value: 'VIC', label: 'Victoria'},
    {value: 'TAS', label: 'Tasmania'},
    {value: 'NT',  label: 'Northern Territory'},
    {value: 'SA',  label: 'South Australia'},
    {value: 'WA',  label: 'Western Australia'},
  ];
  selectedState = 'TAS';
  
  constructor(private router: Router, private carbonFootprintService: CarbonFootprintService) { 
    
  }
  
  ngOnInit(): void {
    
  }
  ngAfterViewInit(): void {
    this.customerDetailsLoaded.emit();
  }

  onContactMeChecked(checked: boolean) {
    this.contactMeChecked.emit(checked);
  }

  public next(){
    this.router.navigate(['/']);
  }

  public back(){
    this.router.navigate(['/calculation']);
  }


}
