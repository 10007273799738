import { Injectable } from '@angular/core';
import { ICarbonInput } from '../interfaces/CarbonInput';

@Injectable({
  providedIn: 'root'
})
export class CarbonFootprintService {
  private carbonInput: ICarbonInput = {
    farmType: null,
    livestock: null,
    cropping: null,
    vegetationAndForest: null,
    cattle: null,
    useDse: false,
    dse: null,
    sheep: null,
    dairy: null,
    horticulture: null,
    grainsAndSeeds: null,
    remnantVegetaton: null,
    plantationForest: null,
    grazingPasture: null,
    mixedNativeForest: null,
    total_landArea: null,
    total_offset_calculated: null,
    name: null,
    surname: null,
    email: null,
    phone: null,
    address: null,
    suburb: null,
    postcode: null,
    contact_me: false,
    email_me: false,
    consultant: null,
    event: null,
    datetime: null,
    form_completed: false,
    co2Captured:null,
    co2Output:null,
    carbonFootprint:null,
    calcVersion:null,
    additionalPlantationForest:null,
    captchaToken:null
  };

  constructor() { }

  public getCarbonInput():ICarbonInput{
    return this.carbonInput;
  }
}
