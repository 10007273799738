<header class=" mb-3 header-corner">
  <div class="container">
    <div class="d-flex justify-content-center py-2 container-padding">

        <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
          <img src="https://www.iconpacks.net/icons/2/free-tree-icon-1578-thumb.png" width="40" height="32" atl="tree">
        </a>
  
        <ul class="nav nav-pills">
          <li><a href="#" class="nav-link px-2 link-secondary app-heading-2">Carbon Footprint Estimator</a></li>
        </ul>
  
        </div>
      </div>
    </header>