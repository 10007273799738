<div class="">
  <main class="progress-container">
    <div class="card mb-3">
      <div class="col-12 card text-white bg-primary mb-3 card-top-section-padding">
        <div class="card-body">
          <p class="card-text text-center app-heading-3 text-white">Result</p>
        </div>
      </div>

      <div class="mb-2">
        <progressbar [animate]="true" [value]="prograssVal" type="success"><b>{{prograssVal}}%</b></progressbar>
      </div>
      </div>
    </main>
    <mat-card class="card-container">
      <!-- <mat-card-title class="text-center app-heading-2">Carbon Footprint Result</mat-card-title> -->
      <div class="row">
        <div class="col-xl-5" >
          <!-- <span style="width: 100%; height: 100px;"></span> -->
          <!-- container -->
          <div class="summary-container">
            <div>
              <div class="app-heading-3 summary-item-title text-start">Summary</div>
              <table class="table table-bordered border-primary">
                <thead>
                  <tr>
                    <th colspan="4" scope="col">Estimated Annual Carbon</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr>
                    <td>Emitting <button class="btn btn-outline-secondary btn-sm btn-circle" tooltip="How much carbon are you land is releasing?"><i class="fa fa-info"></i></button></td>
                    <td>Sequestering <button class="btn btn-outline-secondary btn-sm btn-circle" tooltip="How much carbon your land currently captures?"><i class="fa fa-info"></i></button></td>
                    <td>Difference <button class="btn btn-outline-secondary btn-sm btn-circle" tooltip="The difference required to determine if you are Carbon Neutral."><i class="fa fa-info"></i></button></td>
                    <td>Plantation Required <button class="btn btn-outline-secondary btn-sm btn-circle" tooltip="The area of additional plantation required to reach a Carbon Neutral position."><i class="fa fa-info"></i></button></td>
                  </tr>
                  <tr>
                    <td>{{dataObj.Co2Output}}</td>
                    <td>{{dataObj.Co2Captured}}</td>
                    <td>{{dataObj.carbonFootprint}}</td>
                    <td>{{dataObj.plantRequired}} Ha</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-xl-7" #gaugeContainer>
          <!--<span #gaugePositiveLabel class="object-rectangle fs-6 text">Sequestering</span>
          <span #gaugeNegativeLabel class="object-rectangle fs-6 text">Emitting</span>-->
          <highcharts-chart 
          [Highcharts]="Highcharts"
          [constructorType]="constructorType"
          [options]="gaugeChartOptions"
          [callbackFunction]="gaugeChartCallback"
          [(update)]="gaugeChartUpdateFlag"
          style="width: 100%; display: block;"></highcharts-chart>
          <span class="right-label">Sequestering</span>
          <span class="left-label">Emitting</span>
        </div>
      </div>
      <mat-card-actions class="slider-contiainer text-center h5">
        Use the slider below to show how many hectares of plantation forest can offset your carbon emissions.
      </mat-card-actions>
      <mat-card-actions class="slider-contiainer border border-primary text-center bg-colour">
        <p class="lead app-heading-3">
          Additional hectares of Plantation Forest
        </p>
  
        <mat-slider class=""
        (input)="onSliderChange($event)"
        [max]="sliderMax"
        [min]="sliderMin"
        [(ngModel)]="capturedInputs.plantationForest"
        [thumbLabel]=true
        ></mat-slider>
  
    </mat-card-actions>
      <section class=" check-box-container d-flex justify-content-center">
        <div>
          <mat-checkbox class="app-text"  (change)="onEmailChecked($event.checked)" [(ngModel)]="isEmailMeNeeded">Email me a copy of my results</mat-checkbox>
        </div>
      </section>
    </mat-card>
    
    <ng-container *ngIf="isEmailMeNeeded">
      <section class="card-container text-center">
        <app-customer-details id="customerDetails" (contactMeChecked)="onContactMeCheckedCallback($event)"
        (customerDetailsLoaded)="onCustomerDetailsLoaded()"></app-customer-details>
      </section>
    </ng-container>
  
    <ng-container *ngIf="isEmailMeNeeded">
      <mat-card class="d-flex justify-content-center recaptchaContainer">
        <app-recaptcha (recaptchaEvent)="onRecaptchaResponse($event)"></app-recaptcha>
      </mat-card>
    </ng-container>
  
    <div class="d-flex justify-content-around flex-wrap navigation-button-container">
      <button class="col-3 btn btn-secondary navigation-button app-button-text" type="button" (click)="back()">{{ 'back' | uppercase }}</button>
      <button class="col-3 btn btn-primary navigation-button app-button-text" [disabled]="isSubmitDisabled" style="color:#fff" type="button" (click)="submit()">{{ 'submit' | uppercase }}</button>
    </div>
</div>


  


  
