import { Component, Input, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ICarbonInput } from '../interfaces/CarbonInput';
import { CarbonFootprintService } from '../services/carbon-footprint.service';
import { StorageService } from '../services/storage.service';


@Component({
  selector: 'app-capture-input',
  templateUrl: './capture-input.component.html',
  styleUrls: ['./capture-input.component.scss']
})
export class CaptureInputComponent implements OnInit {
  capturedInputs: ICarbonInput;
  callFrom = "";
  farmType = "";
  
  isBackBtnShown: boolean = false;
  prograssVal: number = 0;
  durationInSeconds: number = 5;

  isLandAreaCollapsed: boolean = false;
  isCO2OutputCollapsed: boolean = false;
  isCO2CaptureCollapsed: boolean = false;
  sectionTracker: number = 0;
  nextBtnLabel: string = "next";

  constructor(private router: Router, private route: ActivatedRoute, private carbonFootprintService: CarbonFootprintService,
    private storageService: StorageService, private _snackBar: MatSnackBar) { 
    
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) =>{
      
      this.callFrom = params['from'];
      this.farmType = params['farmType'];
      if (this.farmType == null) this.farmType = 'any'
    });
    this.init();
    if(this.callFrom){
      const previousPage = this.storageService.getInputPageNum();
      if(previousPage === 4){
      this.sectionTracker =2;
      this.stageTwo();
      }else{
        this.stageZero();    
      }
    }else{
      this.stageZero();
    }


  }

  init(): void{
    this.capturedInputs = this.carbonFootprintService.getCarbonInput();    
    if (this.farmType && this.capturedInputs.farmType == null){
      this.capturedInputs.farmType = this.farmType;
      if (this.farmType.toLowerCase() == 'stock'){
        this.capturedInputs.useDse = true;
      }
    }
  }

  loadTestData(){
    this.capturedInputs.livestock = 40;
    this.capturedInputs.cropping = 5;
    this.capturedInputs.vegetationAndForest = 5;
    this.capturedInputs.total_landArea = (this.capturedInputs.livestock + this.capturedInputs.cropping + this.capturedInputs.vegetationAndForest);
    this.capturedInputs.cattle = 75;
    this.capturedInputs.sheep = 50;
    this.capturedInputs.dairy = 0;
    this.capturedInputs.horticulture = 0;
    this.capturedInputs.grainsAndSeeds = 5;
    this.capturedInputs.remnantVegetaton = 4;
    this.capturedInputs.plantationForest = 1;
    this.capturedInputs.grazingPasture = 40;
    this.capturedInputs.mixedNativeForest = 0;
  }

  moveStage(stage:number){
    switch (stage){
      case 1:
        this.stageOne();
        break;
      case 2:
         this.stageTwo();
         break;
      default:
        this.stageZero();
    }
  }

  stageTest(){
    this.sectionTracker = 2;
    this.prograssVal = 75;
    this.isLandAreaCollapsed = true;
    this.isCO2OutputCollapsed = true;
    this.isCO2CaptureCollapsed = false;
    this.nextBtnLabel = 'calculate';
    this.isBackBtnShown = true;
    this.storageService.storeInputPageNum(2);
  }

  stageZero(){
    this.prograssVal = 0;
    this.isLandAreaCollapsed = false;
    this.isCO2OutputCollapsed = true;
    this.isCO2CaptureCollapsed = true;
    this.nextBtnLabel = 'next';
    this.isBackBtnShown = false;
    this.storageService.storeInputPageNum(0);
  }
  stageOne(){
    this.prograssVal = 50;
    this.isLandAreaCollapsed = true;
    this.isCO2OutputCollapsed = false;
    this.isCO2CaptureCollapsed = true;
    this.nextBtnLabel = 'next';
    this.isBackBtnShown = true;
    this.storageService.storeInputPageNum(1);
  }
  stageTwo(){
    this.prograssVal = 75;
    this.isLandAreaCollapsed = true;
    this.isCO2OutputCollapsed = true;
    this.isCO2CaptureCollapsed = false;
    this.nextBtnLabel = 'calculate';
    this.isBackBtnShown = true;
    this.storageService.storeInputPageNum(2);
  }

  pastureInput(event:any){
    const pastureVal = event.target.value;
    //update the grazingPasture
    this.capturedInputs.grazingPasture = pastureVal;
  }

  back(){
    if(this.sectionTracker == 0){
      this.router.navigate(['/']);
    }

    if(this.sectionTracker > 0) this.sectionTracker--
    this.moveStage(this.sectionTracker);
  }
  next(){
    if(!this.validateInputs(this.sectionTracker)){
      console.error("Your inputs are invalid");
      const errorMsg = "At least one of the values needs to be entered";
      this.openSnackBar(errorMsg);
      return;
    }
    
    if(this.sectionTracker == 2)this.router.navigate(['/calculation']);
    
    if(this.sectionTracker < 2) this.sectionTracker++
    this.moveStage(this.sectionTracker);
  }
  private validateInputs(stage: number):boolean{
    let isValidated = false;
    switch (stage){
      case 0:
        isValidated = this.validateStageZero();
        break;
      case 1:
        isValidated = this.validateStageOne();
         break;
      case 2:
        isValidated = true;//this.validateStageTwo();
         break;
      default:
        //do nothing
        isValidated = false;
    }
    return isValidated;
  }

  private validateStageZero():boolean{
    const {livestock, cropping, vegetationAndForest} = this.capturedInputs;

    let isValidated = false;
    if(livestock || cropping || vegetationAndForest){
      isValidated = true;
    }
    return isValidated ;
  }
  private validateStageOne():boolean{
    const {cattle, sheep, dairy, horticulture, grainsAndSeeds, dse, useDse} = this.capturedInputs;

    let isValidated = false;
    if (useDse && dse){
      isValidated = true;
    } else if(!useDse && (cattle || sheep || dairy || horticulture || grainsAndSeeds)){
      isValidated = true;
    }
    return isValidated ;
  }

  private validateStageTwo():boolean{
    const {remnantVegetaton, plantationForest, mixedNativeForest} = this.capturedInputs;

    let isValidated = false;
    if(remnantVegetaton || plantationForest ||mixedNativeForest ){
      isValidated = true;
    }
    return isValidated ;
  }

  public openSnackBar(errorMessage: string) {
    this._snackBar.open(`Error: ${errorMessage}`, '', {
      duration: this.durationInSeconds * 1000,
      panelClass: ['red-snackbar'],
    });
  }
  

}
