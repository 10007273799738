import { Injectable } from '@angular/core';

const key:string ="input-page-number";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  

  constructor() { }

  storeInputPageNum(pageNum: number){
    localStorage.setItem(key, pageNum.toString());

  }

  getInputPageNum():number{
    return parseInt(localStorage.getItem(key));
  }

  clearStorage(){
    localStorage.clear();
  }
}
