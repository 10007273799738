import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFormulaConfig, IRecaptchaKeys, IVersionConfig } from '../interfaces/CarbonConfig';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private appConfig: any;

  constructor(private http: HttpClient) { }
  loadAppConfig() {
    return this.http.get('../../assets/config.json')
      .toPromise()
      .then(data => {
        this.appConfig = data;
      });
  }
  // This is an example property ... you can make it however you want.
  getRecaptcha():IRecaptchaKeys {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.recaptcha;
  }
  getConfigFunctionKey():string {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.configFunctionKey;
  }
  getFormulaConfigObj():IFormulaConfig {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.formularObj;
  }

  getConfigVersion():IVersionConfig{
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.version;
  }
}
