import { Component, OnInit, Output } from '@angular/core';
import { ICarbonInput } from '../interfaces/CarbonInput';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  
  pageNum: number = 0;
  isFlowStarted = false;
  capturedInputs: ICarbonInput = {
    farmType: null,
    livestock: null,
    cropping: null,
    vegetationAndForest: null,
    useDse: false,
    dse: null,
    cattle: null,
    sheep: null,
    dairy: null,
    horticulture: null,
    grainsAndSeeds: null,
    remnantVegetaton: null,
    plantationForest: null,
    grazingPasture: null,
    mixedNativeForest: null,
    total_landArea: null,
    total_offset_calculated: null,
    name: null,
    surname: null,
    email: null,
    phone: null,
    address: null,
    suburb: null,
    postcode: null,
    contact_me: false,
    email_me: false,
    consultant: null,
    event: null,
    datetime: null,
    form_completed: false,
    additionalPlantationForest:null,
    calcVersion:null,
    carbonFootprint:null,
    co2Captured:null,
    co2Output:null,
    captchaToken: null
  };

  constructor() { }

  ngOnInit(): void {
  }

  startFlow(){
    this.isFlowStarted = true;
  }

  onHomeFinished(isCalculationStarted:boolean){
    this.startFlow();
    this.increasePageNumber();
  }
  
  increasePageNumber(){
    this.pageNum = this.pageNum +1;
  }
}
