import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { AppConfigService } from '../services/app-config.service';
import {  IRecaptchaKeys } from '../interfaces/CarbonConfig';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit {
  @Output() recaptchaEvent = new EventEmitter<string>();
  
  recaptchaContainer: IRecaptchaKeys;
  recaptchaVerifyUrl: string =
  'https://database/api/siteverify';
  constructor(private http: HttpClient, private appConfigService: AppConfigService)  { }

  ngOnInit(): void {
    this.recaptchaContainer = this.getRecaptchaKey();
    
  }

  getRecaptchaKey(){
    return this.appConfigService.getRecaptcha();
    
  }

  resolved(captchaResponse: string) {
    this.recaptchaEvent.emit(captchaResponse);
  }

  verify(responseToken: string){
    const bodyParams = {
      'response': responseToken
    }
    //secret, response, remoteip(optional)
    return this.http
    .post(this.recaptchaVerifyUrl,bodyParams)
    .pipe(
      retry(3),
      catchError(this.handleError<any>('verify recaptch', [])));

    //output the result to the parent component to continue the flow
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
