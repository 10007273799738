
<div class="d-flex h-100 text-center">
  <div class="container">

    <div class="card text-center card-top">
      <div class="card-header">
        <div class="app-heading-2">
          Disclaimer
        </div>
      </div>
      <div class="card-body">
        <section class="text-center">
          <p class="lead card-text app-text card-body-text">
            This is a free trial carbon estimation tool, it is not intended to be used in financial or business decisions but to be used as a guide from which more detailed formal Carbon Assessments can be undertake by registered providers.
          </p>
          <p class="lead card-text app-text card-body-text">
            The model uses generic reference points including but not limited published reports: <i>CSIRO Offsets required to reduce the carbon balance of sheep and beef farms through carbon sequestration in trees and soils, Farming carbon - rivers, soils and trees reducing the carbon footprint of Tasmanian Dairy with base calculations from Balmaan (2021) Generalised GHG Reductions from Tasmanian Forest Activities.</i> Both Emission and Sequestration Data sets have been averaged to provide a generic reference point for Tasmania conditions. 
          </p>
          <p class="lead card-text app-text card-body-text">
            It is expected that based on feedback and emerging studies that reference data will continue to be refined.
          </p>
            <p class="lead card-text app-text card-body-text">
              No warranty is given that the information is free of infection by computer viruses or other contamination, nor that access to the website or any part of it will not suffer from interruption from time to time, without notice.
            </p>
            <p class="lead card-text app-text card-body-text">
              Any links to other websites that have been included on this website are provided for your convenience only. 
            </p>
            <p class="lead card-text app-text card-body-text">
              The Hub is funded by the Commonwealth Government under the National Forest Industries Plan.
            </p>
        </section>
      </div>
      <div class="card-footer text-muted">
        
      </div>
    </div>
    <div class="card text-center card-top">
      <div class="card-header">
        <div class="app-heading-2">
          Privacy
        </div>
      </div>
      <div class="card-body">
        <!-- <h5 class="card-title">Special title treatment</h5> -->
        <section class="text-center">
            <p class="lead card-text app-text card-body-text">
              We are committed to protecting the information we collect and use by compliance with the obligations provided under the <i>Personal Information Protection Act 2004.</i>
            </p>
            <p class="lead card-text app-text card-body-text">
              We will ensure that your personal information will not be used or disclosed to other State institutions and authorities except if required or allowed by law.
            </p>
            <p class="lead card-text app-text card-body-text">
              We may collect non-personal information in gathering statistical information on how our websites are used, or to assist you in navigating through our websites.
            </p>
            <p class="lead card-text app-text card-body-text">
              We have implemented technology and security policies, rules and measures to protect the personal information that we have under our control from unauthorised access, improper use, unsanctioned alteration, unlawful or accidental destruction and accidental loss.              
            </p>
        </section>
      </div>
      <div class="card-footer text-muted">
        
      </div>
    </div>
    </div>
</div>