<div class="container">
    <main>
        <div class="card mb-3">
          <div class="col-12 card text-white bg-primary mb-3 card-top-section-padding">
            <div class="card-body">
              <p class="card-text app-text text-white">The questions below are used to assist in estimating the carbon footprint of your farm operations.</p>
            </div>
          </div>

          <div class="mb-2">
            <progressbar [animate]="true" [value]="prograssVal" type="success"><b>{{prograssVal}}%</b></progressbar>
          </div>

            <div class="card-body" [collapse]="isLandAreaCollapsed" [isAnimated]="false">
              
              <p class="app-heading-2">How do you use your land?</p>

              <section class="card-section">
                <p class="app-heading-3">
                  Land Area
                  <img class="land-icon" src="../../assets/land-plots.svg" />
                </p>
              </section>

              <section class="card-section">
                <div class="row">
                  <div class="col col-xs-4" *ngIf="capturedInputs.farmType && capturedInputs.farmType == 'any'">
                    <mat-form-field appearance="outline">
                      <mat-label class="app-text">Total Area Cropping/Horticulture</mat-label>
                      <input matInput type="number" id="croppingInput" (input)="pastureInput($event)" [(ngModel)]="capturedInputs.cropping" placeholder="0">
                      
                      <mat-hint align="end">
                        <font size="2" class="app-disclaimer">hectare</font>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col col-xs-4">
                    <mat-form-field appearance="outline">
                      <mat-label class="app-text">Total Area Grazing Pastures</mat-label>
                      <input matInput type="number" id="liveStockInput" [(ngModel)]="capturedInputs.livestock" placeholder="0">
                      <mat-hint align="end">
                        <font size="2" class="app-disclaimer">hectare</font>
                      </mat-hint>
                    </mat-form-field>

                  </div>
                  <div class="col col-xs-4">
                    <mat-form-field appearance="outline">
                      <mat-label class="app-text">Total Area Remnant Vegetation</mat-label>
                      <input matInput type="number" id="remnantVegetationInput" [(ngModel)]="capturedInputs.remnantVegetaton" placeholder="0">
                      <!--<input matInput type="number" id="vegetationAndForestInput" [(ngModel)]="capturedInputs.vegetationAndForest" placeholder="0">-->
                      <mat-hint align="start" >
                        <font size="2" class="app-disclaimer-strong">
                          <strong>i.e. woody scrubland, native forest, etc.</strong>
                      </font>
                    </mat-hint>
                      <mat-hint align="end">
                        <font size="2" class="app-disclaimer">hectare</font>
                      </mat-hint>
                    </mat-form-field>

                  </div>
                </div>
              </section>
              
            </div>

            <div class="card-body" [collapse]="isCO2OutputCollapsed" [isAnimated]="false">
              <p class="app-heading-2">What kind of livestock do you have on your farm?</p>
              
              <section class="card-section">
                <p class="app-heading-3">
                  Livestock
                  <img class="cow-icon" src="../../assets/cow.svg" />
                </p>
              </section>

              <section class="card-section">
                <div class="form-check form-switch form-switch-md">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" [(ngModel)]="capturedInputs.useDse"/>
                    <label class="form-check-label" for="flexSwitchCheckDefault">Do you know your average Dry Sheep Equivalent (DSE) per hectare?</label>
                </div>
              </section>

              <section class="card-section" *ngIf="capturedInputs.useDse">
                <div class="row">
                  <div class="col col-xs-4 ">
                      <mat-form-field appearance="outline">
                        <mat-label class="app-text">DSE</mat-label>
                        <input matInput type="number" id="cattleInput" [(ngModel)]="capturedInputs.dse" placeholder="0">
                        <mat-hint align="end">
                          <font size="2" class="app-disclaimer">
                            dry sheep equivalent per hectare (annual average)
                          </font>
                        </mat-hint>
                      </mat-form-field>
                  </div>
                </div>
              </section>

              <section class="card-section" *ngIf="!capturedInputs.useDse">
                <div class="row">
                  <div class="col col-xs-4 ">
                      <mat-form-field appearance="outline">
                        <mat-label class="app-text">Cattle</mat-label>
                        <input matInput type="number" id="cattleInput" [(ngModel)]="capturedInputs.cattle" placeholder="0">
                        <mat-hint align="end">
                          <font size="2" class="app-disclaimer">
                            avg annual herd size
                          </font>
                        </mat-hint>
                      </mat-form-field>
                  </div>
                  <div class="col col-xs-4">
                      <mat-form-field appearance="outline">
                        <mat-label class="app-text">Sheep</mat-label>
                        <input matInput type="number" id="sheepInput" [(ngModel)]="capturedInputs.sheep" placeholder="0">
                        <mat-hint align="end">
                          <font size="2" class="app-disclaimer">
                            avg annual herd size
                          </font>
                        </mat-hint>
                      </mat-form-field>
                  </div>
                  <div class="col col-xs-4">
                      <mat-form-field appearance="outline">
                        <mat-label class="app-text">Dairy</mat-label>
                        <input matInput type="number" id="dairyInput" [(ngModel)]="capturedInputs.dairy" placeholder="0">
                        <mat-hint align="end">
                          <font size="2" class="app-disclaimer">
                            avg annual herd size
                          </font>
                        </mat-hint>
                      </mat-form-field>
                  </div>
                </div>
              </section>

              <div class="divider" *ngIf="capturedInputs.farmType && capturedInputs.farmType == 'any'">
                <hr class="dashed">
              </div>
              <p class="app-heading-2" *ngIf="capturedInputs.farmType && capturedInputs.farmType == 'any'">What kind of cropping are you doing?</p>
              <section class="card-section" *ngIf="capturedInputs.farmType && capturedInputs.farmType == 'any'">
                <p class="app-heading-3">
                  Cropping
                  <img class="sprout-icon" src="../../assets/sprout.svg" />
                </p>
              </section>

              <section class="card-section" *ngIf="capturedInputs.farmType && capturedInputs.farmType == 'any'">
                <div class="row">
                  <div class="col col-xs-6">
                    <mat-form-field appearance="outline">
                      <mat-label class="app-text">Horticulture</mat-label>
                      <input matInput type="number" id="horiticultureInput" [(ngModel)]="capturedInputs.horticulture" placeholder="0">
                      <mat-hint align="end">
                        <font size="2" class="app-disclaimer">hectare</font>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col col-xs-6">
                    <mat-form-field appearance="outline">
                      <mat-label class="app-text">Grains & Seeds</mat-label>
                      <input matInput type="number" id="grainsAndSeedsInput" [(ngModel)]="capturedInputs.grainsAndSeeds" placeholder="0">
                      <mat-hint align="end">
                        <font size="2" class="app-disclaimer">hectare</font>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </section>

            </div>
            <div class="card-body" [collapse]="isCO2CaptureCollapsed" [isAnimated]="false">

              <p class="section-title h2">How is the rest of your farm land used?</p>

              <section class="card-section">
                <p class="app-heading-3">
                  Bushland / Vegetation
                  <img class="forest-icon" src="../../assets/forest.svg" />
                </p>
              </section>

              <section class="card-section">
                <div class="row">
                  <!-- <div class="col col-xs-4 ">
                    <mat-form-field appearance="outline">
                      <mat-label class="app-text">Remnant Vegetation</mat-label>
                      <input matInput type="number" id="remnantVegetationInput" [(ngModel)]="capturedInputs.remnantVegetaton" placeholder="0">
                      <mat-hint align="start" >
                        <font size="2" class="app-disclaimer-strong">
                          <strong>i.e. woody scrubland</strong>
                      </font>
                    </mat-hint>
                      <mat-hint align="end">
                        <font size="2" class="app-disclaimer">per hectare</font>
                      </mat-hint>
                    </mat-form-field>
                  </div> -->
                  <div class="col col-xs-4 ">
                    <mat-form-field appearance="outline">
                      <mat-label class="app-text">Plantation Forest</mat-label>
                      <input matInput type="number" id="plantationForestInput" [(ngModel)]="capturedInputs.plantationForest" min="0" max="30"
                      placeholder="0">
                      <mat-hint align="end">
                        <font size="2" class="app-disclaimer">hectare</font>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col col-xs-4 ">
                    <mat-form-field appearance="outline">
                      <mat-label class="app-text">Mixed Native Forest</mat-label>
                      <input matInput type="number" id="mixedNativeForestInput" [(ngModel)]="capturedInputs.mixedNativeForest" placeholder="0">
                      <mat-hint align="end">
                        <font size="2" class="app-disclaimer">hectare</font>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </section>
            </div>
            
            <div class="d-flex justify-content-between flex-wrap">
              <button class="col-3 btn btn-secondary card-navigation-button btn-xs-block app-button-text" 
              [ngClass]="(isBackBtnShown)?'btnVisible':'btnInvisible'" type="button" (click)="back()">{{ 'back' | uppercase }}</button>
              <button class="col-3 btn btn-primary card-navigation-button btn-xs-block app-button-text" style="color:#fff" type="button" (click)="next()">{{ nextBtnLabel | uppercase }}</button>
            </div>
          </div>

    </main>
</div>