import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @Output() homeFinished = new EventEmitter<boolean>();
  
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  startCalculation(){
    this.router.navigate(['/input', {  farmType: 'stock' }]);
  }
}
